import { RegisterDetails, RegisterFormErrors } from "./types"
import { isValidString, validateEmail } from "../../utils"

export default function validate(registerDetails: RegisterDetails): RegisterFormErrors {
  let errors: RegisterFormErrors = {}
  if (!isValidString(registerDetails.displayName)) {
    errors.nameError = "Please enter your name"
  } else {
    registerDetails.displayName = registerDetails.displayName.trim()
    if (registerDetails.displayName.length == 0) {
      errors.nameError = "Please enter your name"
    }
  }

  if (!isValidString(registerDetails.email)) {
    errors.emailError = "Please enter a valid email"
  } else if (!validateEmail(registerDetails.email)) {
    errors.emailError = "Please enter a valid email"
  }

  if (!isValidString(registerDetails.password)) {
    errors.passwordError = "Please enter a password"
  } else if (registerDetails.password.length < 8) {
    errors.passwordError = "Password must be 8 or more characters"
  } else if (registerDetails.password.length > 25) {
    errors.passwordError = "Password must be 25 or less characters"
  }

  return errors
}