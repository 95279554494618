import React from "react"
import Layout from "../components/layout"
import { AnonymousOnlyRoute } from "../features/routing/Routes"
import Register from "../features/register/Register"

const RegisterPage = () => {

  return (
    <Layout>
      <AnonymousOnlyRoute component={Register}/>
    </Layout>
  )
}

export default RegisterPage