import React from "react"
import { ModalBgBox } from "../../components/SharedStyledComponents"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch, RootState } from "../../redux/store"
import { ErrorInfo, Profile, Resource, Status } from "../../redux/types"
import RegisterForm from "./RegisterForm"
import RegisterSuccess from "./ResgiterSuccess"
import { register, reset } from "./registerSlice"
import SEO from "../../components/seo"

import { graphql, navigate, useStaticQuery } from "gatsby"
import BackdropLoader from "../../components/BackdropLoader"
import { REGISTER_ERROR_EMAIL_USED } from "./types"
import StyledSnackbar from "../../components/StyledSnackbar"
import AlertDialog from "../../components/AlertDialog"
import { LOGIN } from "../routing/Locations"
import { BGBlurImage } from "../../components/BGBlurImage"
import { makeStyles } from "@material-ui/core/styles"
import MenuWithLogo from "../menu/MenuWithLogo"

export default function Register() {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }
  }))

  const { desktop } = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "DSC01827.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, webpQuality: 100, pngQuality: 100, jpegQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )


  const profileResource = useSelector((state: RootState) => state.register)
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <>
      <SEO title="Sign Up"/>
      <BGBlurImage imageUrl={""} localImage={desktop.childImageSharp.fluid} lightBlur={true}/>
      <MenuWithLogo memberMenu={false} showWelcome={false} blackIcon={false}/>
      <div className={classes.root}>
        <ModalBgBox>
          {(profileResource.status != Status.FINISHED) &&
          <RegisterForm register={(details) => dispatch(register(details))}/>}
          {profileResource.status == Status.FINISHED && <RegisterSuccess/>}
        </ModalBgBox>
      </div>
      {showErrors(profileResource, dispatch)}
      <BackdropLoader show={profileResource.status == Status.LOADING}/>
    </>
  )
}

function showErrors(resource: Resource<Profile>, dispatch: Dispatch) {
  if (resource.status != Status.ERROR || !resource.error)
    return null

  if (resource.error.code == REGISTER_ERROR_EMAIL_USED)
    return showAlreadyEmailExistsError(resource.error, dispatch)


  return <StyledSnackbar show={true} severity={"error"} message={resource.error.message}/>
}

function showAlreadyEmailExistsError(errorInfo: ErrorInfo, dispatch: Dispatch) {
  return <AlertDialog show={true} message={errorInfo.message} positiveButton="Login" positiveClick={() => {
    dispatch(reset())
    navigate(LOGIN, { replace: true })
  }
  }/>
}
