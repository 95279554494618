import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import StyledButton from "../../components/StyledButton"
import { WORKOUTS_HOME } from "../routing/Locations"
import { navigate } from "gatsby"
import { Box, Typography } from "@material-ui/core"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 10, 0)
  },
  submit: {
    width: "80%",
    margin: theme.spacing(4, "auto", 3)
  }
}))

export default function RegisterSuccess() {
  const redirectSuccess = useSelector((state: RootState) => state.route.redirectSuccess)

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant={"h2"} align={"center"}>TIME TO SWEAT</Typography>
      <Box mt={4}>
        <Typography variant={"body1"} align={"center"}>
          You’re on your way to becoming the best version of yourself. The hardest part has already been done.
        </Typography>
      </Box>
      <StyledButton
        className={classes.submit}
        text={"here we go"}
        onClick={() => navigate(redirectSuccess != null && redirectSuccess.length > 0 ? redirectSuccess : WORKOUTS_HOME, { replace: true })}/>
    </div>
  )
}