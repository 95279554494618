import StyledTextField, { PasswordTextField } from "../../components/TextFieldInput"
import React from "react"
// @ts-ignore
import useForm from "./useForm"
import validate from "./RegisterFormValidationRules"
import { makeStyles } from "@material-ui/core/styles"
import { RegisterDetails } from "./types"
import StyledButton from "../../components/StyledButton"
import { Link } from "gatsby"
import { LOGIN } from "../routing/Locations"
import { Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"

type RegisterComplete = (n: RegisterDetails) => any;

interface RegisterFormProps {
  register: RegisterComplete;
}

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  form: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "280px", // Fix IE 11 issue.
    margin: theme.spacing(2),
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(4, 0, 1)
  }
}))

export default function RegisterForm(props: RegisterFormProps) {

  const classes = useStyles()
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useForm(() => props.register(values as RegisterDetails), validate)


  // @ts-ignore
  return (
    <><Typography align="center" variant={"h2"}>YOUR FITNESS REVOLUTION</Typography>
      <Box mt={0.5}>
        <Typography align="center" variant={"h2"}>STARTS NOW</Typography>
      </Box>
      <div className={classes.form}>
        <StyledTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="name"
          label="Enter name"
          name="displayName"
          onChange={handleChange}
          value={values.displayName || ""}
          helperText={errors.nameError}
          error={errors.nameError !== undefined}
          autoComplete="name"
        />
        <StyledTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Enter email"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          value={values.email || ""}
          helperText={errors.emailError}
          error={errors.emailError !== undefined}
        />
        <PasswordTextField
          show={true}
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Create a password"
          id="password"
          autoComplete="new-password"
          onChange={handleChange}
          value={values.password || ""}
          helperText={errors.passwordError}
          error={errors.passwordError !== undefined}
        />
        <StyledButton
          text="start"
          onClick={handleSubmit}
          className={classes.submit}/>
        <Link to={LOGIN}><Typography variant={"body2"}>I already have an account</Typography></Link>
      </div>
    </>)
}